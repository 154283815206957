* {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    color: #282828;
    font-size: 12pt;
    font-family: "Titillium Web", "Roboto", "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@media (min-width: 700px) {
    /* Scrollbars */

    ::-webkit-scrollbar {
      width: 10px;
    }

    ::-webkit-scrollbar-track {
      background: white;
    }

    ::-webkit-scrollbar-thumb {
      background: rgb(245, 183, 36);
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: rgb(186, 139, 26);
    }
}

.cancelSubscription {
    text-align: right;
    margin-right: 55px;
    margin-left: 140px;
    cursor: pointer;
}

@media (max-width: 800px) {
    .BeaconFabButtonFrame {
        background-color: rgb(62, 100, 152);
        bottom: 0px !important;
        border-radius: 0 !important;
        left: 0;
        right: 0;
        width: 100% !important;
    }
}

#cc-main {
     --cc-btn-primary-bg: green;
     --cc-btn-primary-border-color: green;
     --cc-btn-primary-hover-bg: black;
     --cc-btn-primary-hover-border-color: black;
     --cc-btn-secondary-bg: grey;
     --cc-btn-secondary-color: white;
     --cc-btn-secondary-hover-bg: black;
     --cc-btn-secondary-hover-color: white;
     --cc-btn-secondary-hover-border-color: black;
     --cc-toggle-on-bg: var(--cc-btn-primary-bg);
     --cc-btn-border-radius: 10px;
 }
